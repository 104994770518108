<template>
	<div class="row align-items-start">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
			<div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
				<strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
				<span>Gerencie os estoques disponíveis rapidamente. Adicione, atualize ou delete um estoque.</span>
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
			</div>
		</div>

		<div class="col-12">
			<div class="card mb-2">
				<div class="card-body">
					<div class="row">
						<div class="col">
							<label><i class="far fa-box color-theme font-11 me-1"></i> Filtrar</label>
							<input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus placeholder="Busque por nome do estoque ou loja" />
						</div>
						<div class="w-max-content align-self-end" v-if="dadosUsuario.usuarioPermissoes.includes('Configuração | Editar gerenciamento de estoques')">
							<button type="button" class="btn btn-primary w-100 px-sm-5" @click="abrirModal(null)">
								<i class="far fa-plus font-13 me-1"></i> Adicionar
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 px-0" v-if="pesquisa.resultado.length > 0">
			<div class="row estoques">
				<estoque v-for="(estoque, index) in pesquisa.resultado" :key="index" :estoque="estoque" :index="index" @editar="abrirModal($event)" />

				<div class="col-12 my-5 text-center" v-if="pesquisa.resultado.length == 0">
					<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
					<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum estoque encontrado</h4>
				</div>
			</div>
		</div>

		<!-- modalEstoque -->
      <div class="modal fade" id="modalEstoque" tabindex="-1" aria-labelledby="modalEstoqueLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalEstoqueLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} estoque {{ modal.id == null ? '' : ('('+ modal.nome +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-5">
							<div class="card-body p-12">
								<div class="row">
									<div class="col-12 mb-3">
										<label><i class="far fa-store color-theme font-11 me-1"></i> Loja *</label>
										<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="modal.idLoja" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Selecione uma opção">
											<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
											<template v-slot:no-options>
												<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
											</template>
										</v-select>
										<select name="Mobile" class="form-control" v-model="modal.idLoja">
											<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
										</select>
									</div>
									<div class="col-12">
										<label><i class="far fa-box color-theme font-11 me-1"></i> Nome *</label>
										<input type="text" class="form-control" v-model="modal.nome" maxlength="200" />
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="salvarEstoque">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
	</div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import estoque from '@/components/configuracoes/gerenciamentoEstoque/Estoque.vue'

export default {
	name: 'Gerenciamento_de_estoques',
	data: function () {
		return {
			pesquisa: {'nome': '', 'resultado': []},
			modal: {'id': null, 'nome': null, 'idLoja': null, 'historico': []},
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function () {
			this.buscarEstoques()
      },
		'pesquisa.nome' : function (val) {
         var value = val.toLowerCase()

         $(".row.estoques > *").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
         })
      }
	},
	components: {
		estoque
	},
	methods: {
		imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
		abrirModal : function (estoque) {
			if (estoque == null) {
				Object.assign(this.$data.modal, this.$options.data.apply(this).modal)

			} else {
				this.modal = {'id': estoque.id, 'nome': estoque.nome, 'idLoja': estoque.idLoja, 'historico': []}
			}

			$('#modalEstoque').modal('show')
		},
		buscarEstoques : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.pesquisa.resultado = []

			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoes/searchEstoques'

			}).then(response => {
				this.pesquisa.resultado = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		salvarEstoque : function () {
			if (this.modal.nome == null || String(this.modal.nome).trim().length == 0 || this.modal.idLoja == null) {
				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoes/saveEstoque',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

			}).then(() => {
				$('#modalEstoque').modal('hide')
				this.buscarEstoques()

				this.$toast.fire({
					icon: 'success',
					title: 'Estoque salvo!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
	},
	mounted() {
		this.buscarEstoques()
	}
}

</script>